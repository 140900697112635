import {
  faText,
  faFaceViewfinder,
  faEraser,
  faBrush,
  faImages,
  faPhotoVideo,
  faWaveform,
} from '@fortawesome/pro-regular-svg-icons';
import featureFlagMixin from '@motionelements/core/src/components/mixins/feature-flag.mixin.js';

import { faClapperboardPlay } from '@fortawesome/pro-solid-svg-icons';

export const aiToolsIds = {
  VIDEO_SCRIPTWRITER: 'video_scriptwriter',
  FACE_SWAP: 'face_swap',
  BACKGROUND_REMOVER: 'background_remover',
  STYLE_TRANSFER: 'style_transfer',
  IMAGE_VARIATIONS: 'image_variations',
  VIDEO_UPSCALER: 'video_upscaler',
  // coming soon
  IMAGE_TO_VIDEO: 'image_to_video',
  IMAGE_GENERATOR: 'image_generator',
  IMAGE_ENHANCER: 'image_enhancer',
  MUSIC_DURATION_ADJUSTER: 'music_duration_adjuster',
};

export const aiTools = [
  {
    id: aiToolsIds.VIDEO_SCRIPTWRITER,
    link: '/video-scriptwriter',
    icon: faText,
    released: true,
    mediaType: 'video',
  },
  {
    id: aiToolsIds.FACE_SWAP,
    link: '/face-swap',
    icon: faFaceViewfinder,
    model: 'image_face_swap',
    mediaType: 'image',
    released: featureFlagMixin.methods.isFeatureEnabled('image_face_swap_ai_tool'),
  },
  {
    id: aiToolsIds.BACKGROUND_REMOVER,
    link: '/background-remover',
    icon: faEraser,
    model: 'image_remove_background',
    mediaType: 'image',
    released: true,
  },
  {
    id: aiToolsIds.STYLE_TRANSFER,
    link: '/style-transfer',
    icon: faBrush,
    model: 'style_',
    mediaType: 'image',
    released: true,
  },
  {
    id: aiToolsIds.IMAGE_VARIATIONS,
    link: '/image-variations',
    icon: faImages,
    model: 'image_variation',
    mediaType: 'image',
    released: true,
  },
  {
    id: aiToolsIds.VIDEO_UPSCALER,
    link: '/video-upscaler',
    icon: faClapperboardPlay,
    model: 'video_upscale_2x',
    mediaType: 'video',
    released: featureFlagMixin.methods.isFeatureEnabled('video_upscaler_ai_tool'),
  },
  // coming soon
  {
    id: aiToolsIds.IMAGE_TO_VIDEO,
    link: '/image-to-video',
    icon: faPhotoVideo,
    model: 'image_to_video',
    mediaType: 'video',
    released: featureFlagMixin.methods.isFeatureEnabled('image_to_video_ai_tool'),
  },
  {
    id: aiToolsIds.IMAGE_GENERATOR,
    link: '/image-generator',
    icon: '',
    model: '',
    mediaType: 'image',
    released: false,
  },
  {
    id: aiToolsIds.IMAGE_ENHANCER,
    link: '/image-enhancer',
    icon: '',
    model: '',
    mediaType: 'image',
    released: false,
  },
  {
    id: aiToolsIds.MUSIC_DURATION_ADJUSTER,
    link: '/music-duration-adjuster',
    icon: faWaveform,
    model: 'music_duration',
    mediaType: 'music',
    released: featureFlagMixin.methods.isFeatureEnabled('music_duration_adjuster_ai_tool'),
  },
];
